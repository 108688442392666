import PropTypes from 'prop-types'
import React from 'react'
import { useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const Header = props => {
  const { logo, icon } = useStaticQuery(
    graphql`
      query {
        logo: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        icon: file(relativePath: { eq: "icon.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 120) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return (
    <>
      <div id="icon">
        <Img alt="Mow Bros logo." fluid={icon.childImageSharp.fluid} />
      </div>
      <header id="header" style={props.timeout ? { display: 'none' } : {}}>
        <div className="logo">
          <div style={{ width: '65px', height: '55px', marginTop: '7px' }}>
            <Img alt="Mow Bros logo." fluid={logo.childImageSharp.fluid} />
          </div>
        </div>
        <a href="tel:2086314945" className="cta">
          CALL US FOR A FREE QUOTE NOW!
          <br />
          (208) 631-4945
        </a>
        <div className="content">
          <div className="inner">
            <h1 className="main">We Care for Your Lawn,</h1>
            <h1 className="subtitle">So You Can Care for What's Important</h1>
            <h3>(But still make your neighbors jealous of your yard)</h3>
          </div>
        </div>
        <nav>
          <ul>
            <li>
              <button
                onClick={() => {
                  props.onOpenArticle('mow-bros')
                }}
              >
                Mow Bros
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  props.onOpenArticle('about')
                }}
              >
                About
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  props.onOpenArticle('faqs')
                }}
              >
                FAQS
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  props.onOpenArticle('contact')
                }}
              >
                Contact
              </button>
            </li>
          </ul>
        </nav>
      </header>
    </>
  )
}

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
