import PropTypes from 'prop-types'
import React from 'react'
import icon from '../images/icon.png'
import jared from '../images/jared.jpg'
import blower from '../images/blower.svg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="mow-bros"
          className={`${this.props.article === 'mow-bros' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Mow Bros</h2>
          <span className="centered">
            <img
              height="200px"
              width="200px"
              src={icon}
              alt="Mow Bros official logo."
            />
          </span>
          <h3>Peace of Mind</h3>
          <p>
            We get you. After a long day of work, you want to come home to a
            beautiful, thriving, and well-maintained yard. You're managing a
            thousand items in your head, and you don't need your lawn to be yet
            another item on your list. That's exactly why Mow Bros exists. We
            strive to make your yard beautiful so you can just enjoy it, instead
            of slaving away in it. Life is short...free time is shorter. Do you
            want to be spending it on your lawn?
          </p>
          <h3>Want the Best Kept Lawn in the Neighborhood?</h3>
          <p>
            At Mow Bros, we understand how important it is to you to have a
            great looking yard. A green, lush, thriving lawn. Our goal is to
            make your neighbors jealous.
          </p>
          <h3>Why Use Mow Bros?</h3>
          <p>
            With over two decades of lawn care experience, your lawn is bound to
            thrive in our hands. We use the highest quality fertilizers that
            strengthen your grass (not just turn it green), we use proper edgers
            for all concrete boundaries (making a professional, perfect edge),
            and we catch things like billbug damage or inadequate sprinkler
            coverage right away. (We've seen literally hundreds of lawns.) These
            are just a few reasons to give us a call for a quote. Let us help
            you have the best looking yard in the neighborhood.
          </p>
          {close}
        </article>

        <article
          id="faqs"
          className={`${this.props.article === 'faqs' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">FAQS</h2>
          <span
            className="blower"
            style={{
              height: '200px',
            }}
          >
            <img height="225px" src={blower} alt="Leaf blower icon." />
          </span>
          <p>
            <strong>How do I get a quote?</strong>
          </p>
          <p>
            There are many ways to get ahold of us. You can fill call or text us
            at{' '}
            <a href="tel:(208)6314945">
              <strong>(208) 631-4945</strong>
            </a>
            , email us at{' '}
            <a href="mailto:jared@nelsonenterprises.co?subject=Mow%20Bros%20Inquiry">
              <strong>jared@nelsonenterprises.co</strong>
            </a>
            , or fill out the form on our Contact page. We will respond in one
            business day.
          </p>
          <p>
            <strong>When do you come to mow?</strong>
          </p>
          <p>
            You will be put on a route. We will come to mow once a week on the
            same day every week. We start in April and finish in September.
          </p>
          <p>
            <strong>How short will you cut my grass?</strong>
          </p>
          <p>
            We will respect whatever wishes you have for the length of your
            grass. We advise to not go too short because longer grass holds in
            moisture and protects against our super hot summer days.
          </p>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>
          <span className="image main">
            <img src={jared} alt="Photo of owner, Jared Nelson." />
          </span>
          <p>
            Jared Nelson, owner of Mow Bros, has over two decades of experience
            in lawn care. From partnering in a landscape business with a friend
            at the age of 16, to working for multiple lawncare businesses, and
            finally starting his own company to pay his bills in college, Jared
            has taken care of literally hundreds of yards.
          </p>
          <p>
            He took a brief absence away from lawn care to become a software
            engineer. Yet, Jared couldn't leave the feeling of the sun in his
            face and the satisfaction of leaving a lawn looking amazing for too
            long. After a few years of staring at a computer screen and sitting
            in an office chair for entire days, Jared decided it was time to
            return to lawn care.
          </p>
          <p>
            Mow Bros strives to make your lawn look amazing. And we enjoy each
            step of that process thoroughly.
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <form
            method="post"
            netlify-honeypot="bot-field"
            data-netlify="true"
            name="contact"
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          {/* <ul className="icons">
            <li>
              <a
                href="https://twitter.com/HuntaroSan"
                className="icon fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a href="https://codebushi.com" className="icon fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a href="https://codebushi.com" className="icon fa-instagram">
                <span className="label">Instagram</span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/codebushi/gatsby-starter-dimension"
                className="icon fa-github"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul> */}
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
